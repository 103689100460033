<template>
    <section id="referrals">
      <card icon="filter"
            :headline="$t('visitors.topReferrals.headline')"
            :description="$t('visitors.topReferrals.description')"
      >

      </card>
    </section>
</template>

<script>
    export default {
        name: "Referrals"
    }
</script>

<style scoped lang="scss">

</style>
